// Core
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import get from "lodash/get";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  ArticleCard,
  Pagination,
} from "../../components/";
import Layout from "../../layout/";

class BlogTemplate extends React.Component {
  render() {
    const articles = get(this, "props.data.allContentfulJournalArticle.edges");
    const settings = get(this, "props.data.contentfulJournalSettings");
    const { current_page, number_of_pages, pathname } = this.props.pageContext;

    return (
      <Layout
        seo={{
          pathname: pathname,
          title: "Journal",
        }}
      >
        <section className={styles.header}>
          <div className={styles.header__inner}>
            <h1>{settings.title}</h1>
          </div>

          <div
            className={[
              styles.header__background,
              "rellax rellax--background",
            ].join(" ")}
          >
            <GatsbyImage
              alt={settings.title}
              image={settings.backgroundImage.gatsbyImageData}
              loading="eager"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </div>
        </section>

        <section className="container pb100 pt50">
          <div className={styles["articles-grid"]}>
            {articles.map(({ node }) => {
              return (
                <ArticleCard
                  article={node}
                  key={node.slug}
                />
              );
            })}
          </div>

          {number_of_pages > 1 && (
            <div className="mt80">
              <Pagination
                current={current_page}
                pathname={pathname}
                total={number_of_pages}
              />
            </div>
          )}
        </section>
      </Layout>
    );
  }
}

export default BlogTemplate;

export const pageQuery = graphql`
  query BlogTemplateQuery($skip: Int!, $limit: Int!) {
    allContentfulJournalArticle(
      limit: $limit
      skip: $skip
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(aspectRatio: 1.8, layout: FULL_WIDTH, placeholder: BLURRED)
          }
          topic {
            name
            slug
          }
          description {
            description
          }
          author {
            name
            slug
          }
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
    contentfulJournalSettings {
      title
      backgroundImage {
        fixed(height: 628, width: 1200) {
          src
        }
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
